var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.vesselsGeneralList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.vessel'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.vessel'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":function($event){$event.stopPropagation();return _vm.addBuque.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable reduce-text",attrs:{"hover":"","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"loading":_vm.isLoading,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"RowNumber",fn:function({ item }){return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"CodPais",fn:function({ item }){return [_c('td',{staticClass:"center-flag"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.CodPais))]),_c('c-img-extended',{staticClass:"bandera",attrs:{"src":item.Icon,"error-options":{ width: 23, height: 17, text: 'N/A', fontSize: 10, dy: 5}}})],1)])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center align-middle"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)])]}},{key:"Details",fn:function({item, index}){return [_c('td',{staticClass:"py-2 align-middle text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.edit')+' '+_vm.$t('label.vessel'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.edit')+' '+$t('label.vessel'),\n                        placement: 'top-end'\n                      }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }