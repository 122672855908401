<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.vesselsGeneralList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end align-items-center">
              <CButton
                square
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.vessel'),
                  placement: 'top-end'
                }"
                @click.stop="addBuque"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable reduce-text"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="align-middle">
                    {{ item.RowNumber }}
                  </td>
                </template>

                <template #CodPais="{ item }">
                  <td class="center-flag">
                    <div class="d-flex">
                      <span class="mr-1">{{ item.CodPais }}</span>
                      <c-img-extended 
                        :src="item.Icon"
                        :error-options="{ width: 23, height: 17, text: 'N/A', fontSize: 10, dy: 5}"
                        class="bandera"
                      />
                    </div>
                  </td>
                </template>

                <template #Status="{item}">
                  <td class="text-center align-middle">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{$t('label.'+item.Status)}}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="py-2 align-middle text-center">
                      <CButton
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.vessel'),
                          placement: 'top-end'
                        }"
                        @click="toggleEdit(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
  </CRow>
</template>

<script>
import { DateFormater, tableTextHelpers } from '@/_helpers/funciones';
import CImgExtended from '@/components/CImgExtended';
import GeneralMixin from '@/_mixins/general';

//Funcion data() del ciclo.
function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%;',
      filter:false, 
    },
    { key: 'VesselName', label:this.$t('label.vessel'), _classes:'align-middle'},
    { key: 'TipoBuque', label:this.$t('label.vesselType'), _classes:'align-middle'},
    { key: 'CodPais', label: this.$t('label.country'), _classes:'align-middle'},
    { key: 'Imo', label:this.$t('label.IMO'), _classes:'align-middle'},
    { key: 'Teus', label:'TEUS', _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'Loa', label:'LOA', _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'Gross', label:'GROSS', _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'DraftBow', label:this.$t('label.CProa'), _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'DraftStern', label:this.$t('label.CPopa'), _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'Grua', label:this.$t('label.crane'), _classes:'align-middle text-center',_style: 'text-align:center',},
    { key: 'UltimoUsuario', label: this.$t('label.user'), _classes:'align-middle'},
    { key: 'FormatedDate', label: this.$t('label.date'), _classes:'align-middle text-center', _style: 'text-align:center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'text-align:center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px',
      sorter: false, 
      filter: false,
    }
  ];
}

function data() {
  return {
    items: [],
    isLoading: false,
    activePage: 1
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getBuques();
}

//*****Funciones de methods:
function getBuques() {
  this.isLoading = true;

  this.$http.get('Vessel-list', { Filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function toggleEdit(item, index) {
  this.$router.push({ name: 'EditBuque', params: { VesselId: item.VesselId, VesselImage: item.VesselRoute } });
}
function handleSubmit(){
  this.getBuques();
}
function addBuque(){
  this.$router.push({ name: 'AddBuque'});
}


//*****Funcones de computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    TipoBuque: item.TpVesselName,
    CodPais: item.IsoCodeAlpha3,
    Icon: `${this.$store.getters["connection/getBase"]}${item.Icon?item.Icon.replace('public/', '', null, 'i'):''}`,
    Grua: item.VesselCrane? 'SI':'NO',
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'buques',
  mixins: [GeneralMixin],
  components: { CImgExtended },
  data,
  created,
  methods: {
    getBuques,
    toggleEdit,
    handleSubmit,
    addBuque
  },
  computed: {
    formatedItems,
    fields
  }
}
</script>

<style scoped>
.bandera {
  width: 2em;
  height: 1.5em;
}
.reduce-text {
  font-size: 0.8em;
}
.center-flag {
  vertical-align: middle;
}
</style>